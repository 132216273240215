import iconDashboard from '../../../assets/icons/dashboard.svg'
import iconPayer from '../../../assets/icons/payer.svg'

const DefaultStore = {
    showMenu: false,
    activeItem: 0,
    items: [{
        text: "Dashboard",
        icon: iconDashboard,
        permissions: [],
        subItems: []
    },
    {
        text: "Order",
        icon: iconPayer,
        permissions: [],
        subItems: [
            {
                url: "/order/all",
                text: "Orders",
                permissions: []
            }
        ]
    },
    {
        text: "Wallet",
        icon: iconPayer,
        permissions: [],
        subItems: [
            {
                url: "/wallet/all",
                text: "Wallets",
                permissions: []
            },
            {
                url: "/wallet/transaction/all",
                text: "Transactions",
                permissions: []
            }
        ]
    },
    {
        text: "Marketplace",
        icon: iconPayer,
        permissions: [],
        subItems: [
            {
                url: "/marketplace/inventory/all",
                text: "Inventories",
                permissions: []
            },
            {
                url: "/marketplace/deliverable/all",
                text: "Deliverables",
                permissions: []
            },
            {
                url: "/marketplace/item/all",
                text: "Items",
                permissions: []
            }
        ]
    },
    {
        text: "Delivery",
        icon: iconPayer,
        permissions: [],
        subItems: [
            {
                url: "/delivery/route/all",
                text: "Routes",
                permissions: []
            },
            {
                url: "/delivery/area/all",
                text: "Areas",
                permissions: []
            },
            {
                url: "/delivery/pricing/all",
                text: "Pricing",
                permissions: []
            },
            {
                url: "/delivery/delivery-option/all",
                text: "Delivery Options",
                permissions: []
            }
        ]
    },
    {
        text: "Vendor",
        icon: iconPayer,
        permissions: [],
        subItems: [
            {
                url: "/vendor/payout/all",
                text: "Payouts",
                permissions: []
            },
            {
                url: "/vendor/processor-settings",
                text: "Processor Settings",
                permissions: []
            }
        ]
    },
    {
        text: "Tools",
        icon: iconPayer,
        permissions: [],
        subItems: [
            {
                url: "/tools/paystack-transaction/requery",
                text: "Requery Paystack Transaction",
                permissions: []
            }
        ]
    }
    ]
}

export default DefaultStore